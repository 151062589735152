.root-hotsku-shoes {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: -3.6px;
  margin-bottom: 52px;
  user-select: text;
  width: 100%;
}

.form-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 22px;
}

.form-container label {
  color: var(--foggy-grey);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1.6px;
}

.form-container input {
  background-color: #343434;
  border: none;
  border-radius: 6px;
  color: var(--foggy-grey);
  font-family: "Kumbh Sans", sans-serif;
  font-size: 18px;
  font-weight: 300;
  padding-left: 6px;
  padding-right: 50px;
  height: 32px;
  width: 272px;
}

.form-container input:focus {
  outline-color: #5f5f5f;
  outline-style: solid;
  outline-width: 0.2px;
}

.form-container input::placeholder {
  font-size: 18px;
  font-family: 300;
}

.form-container textarea {
  border: none;
  border-radius: 8px;
  background-color: #343434;
  color: var(--foggy-grey);
  float: left;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 16px;
  padding-left: 6px;
  min-width: 180px;
  max-width: 480px;
  min-height: 88px;
  max-height: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 300px;
  height: 140px;
}

.form-container ::-webkit-scrollbar {
  width: 8px !important;
}

.form-container ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #5d5d5d !important;
}

.form-container textarea:focus {
  outline-color: #bacddb;
  outline-width: 2px;
  outline-style: solid;
}

.info-green {
  align-items: center;
  display: flex;
  background-color: #cde990;
  box-shadow: inset 0 0 1.6vmin 0vmin var(--dark-grey);
  border-radius: 4px;
  cursor: default;
  justify-content: center;
  position: absolute;
  pointer-events: none;
  top: -48px;
  left: -26px;
  width: 248px;
  height: 34px;
}

.info-green:active {
  position: absolute !important;
  top: 0 !important;
}

.info-green p {
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: 700;
}

.info-red {
  align-items: center;
  display: flex;
  background-color: #f45050;
  box-shadow: inset 0 0 1.6vmin 0vmin var(--dark-grey);
  border-radius: 0.1em;
  cursor: default;
  justify-content: center;
  position: absolute;
  pointer-events: none;
  top: -48px;
  left: -30px;
  width: 248px;
  height: 34px;
}

.info-red p {
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: 700;
}

.info-red {
  align-items: center;
  display: flex;
  background-color: #f45050;
  box-shadow: inset 0 0 1.6vmin 0vmin var(--dark-grey);
  border-radius: 0.1em;
  cursor: default;
  justify-content: center;
  position: absolute;
  pointer-events: none;
  top: -48px;
  left: -30px;
  width: 248px;
  height: 34px;
}

.info-red p {
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: 700;
}

.form-container button {
  background-color: var(--silver);
  border: none;
  border-radius: 0.6em;
  color: #343434;
  cursor: pointer;
  display: block;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin: 0 auto;
  margin-top: 3px;
  position: relative;
  width: 188px;
  height: 28px;
}

.form-container button:focus,
.form-container button:disabled {
  outline: none;
  background: #aaa;
}

.form-container button:hover {
  background-color: #bacddbc6;
}

.form-container button:focus {
  outline-style: none;
}

.spin {
  display: block;
}

.spin .spinner {
  top: 4px;
  width: 26px;
  display: block;
  position: absolute;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin .spinner::before {
  animation: 0.8s linear infinite spinner;
  border-radius: 50%;
  border: solid 0.25em #999;
  border-bottom-color: #555;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  will-change: transform;
  width: 1.2em;
  height: 1.2em;
}
